export enum UserSettingsConsents {
  readAccountsBasic = "readAccountsBasic",
  readAccountsDetail = "readAccountsDetail",
  readBalances = "readBalances",
  readTransactionsBasic = "readTransactionsBasic",
  readTransactionsCredits = "readTransactionsCredits",
  readTransactionsDebits = "readTransactionsDebits",
  readTransactionsDetail = "readTransactionsDetail",
}

export enum UserSettingsConsentsNames {
  readAccountsBasic = "Просмотр счёта",
  readAccountsDetail = "Просмотр деталей счёта",
  readBalances = "Просмотр балансов счёта",
  readTransactionsBasic = "Просмотр базовых операций",
  readTransactionsCredits = "Просмотр операций по кредитам",
  readTransactionsDebits = "Просмотр операций по дебитам",
  readTransactionsDetail = "Просмотр деталей операций",
}

export enum CountryEnum {
  AF = 1,
  AL = 2,
  AQ = 3,
  DZ = 4,
  AS = 5,
  AD = 6,
  AO = 7,
  AG = 8,
  AZ = 9,
  AR = 10,
  AU = 11,
  AT = 12,
  AB = 13,
  BS = 14,
  BH = 15,
  BD = 16,
  AM = 17,
  BB = 18,
  BE = 19,
  BM = 20,
  BT = 21,
  BO = 22,
  BA = 23,
  BW = 24,
  BV = 25,
  BR = 26,
  IO = 28,
  SB = 29,
  VG = 30,
  BN = 31,
  BG = 32,
  MM = 33,
  BI = 34,
  BY = 35,
  KH = 36,
  CM = 37,
  CA = 38,
  CV = 39,
  KY = 40,
  CF = 41,
  LK = 42,
  TD = 43,
  CL = 44,
  CN = 45,
  TW = 46,
  CX = 47,
  CC = 48,
  CO = 49,
  KM = 50,
  YT = 51,
  CG = 52,
  CD = 53,
  CR = 55,
  HR = 56,
  CU = 57,
  CY = 58,
  CZ = 59,
  BJ = 60,
  DK = 61,
  DM = 62,
  DO = 63,
  EC = 64,
  SV = 65,
  GQ = 66,
  ET = 67,
  ER = 68,
  EE = 69,
  FO = 70,
  FK = 71,
  GS = 72,
  FJ = 73,
  FI = 74,
  AX = 75,
  FR = 76,
  GF = 77,
  PF = 78,
  TF = 79,
  DJ = 80,
  GE = 82,
  GM = 83,
  PS = 84,
  DE = 85,
  GH = 86,
  GI = 87,
  KI = 88,
  GR = 89,
  GL = 90,
  GD = 91,
  GP = 92,
  GU = 93,
  GT = 94,
  GN = 95,
  GY = 96,
  HT = 97,
  HM = 98,
  VA = 99,
  HN = 100,
  HK = 101,
  HU = 102,
  IS = 103,
  IN = 104,
  ID = 105,
  IR = 106,
  IE = 108,
  IL = 109,
  IT = 110,
  CI = 111,
  JM = 112,
  JP = 113,
  KZ = 114,
  JO = 115,
  KE = 116,
  KP = 117,
  KR = 118,
  KW = 119,
  KG = 120,
  LA = 121,
  LB = 122,
  LS = 123,
  LV = 124,
  LR = 125,
  LY = 126,
  LI = 127,
  LT = 128,
  LU = 129,
  MO = 130,
  MG = 131,
  MW = 132,
  MV = 134,
  ML = 135,
  MT = 136,
  MQ = 137,
  MR = 138,
  MU = 139,
  MX = 140,
  MC = 141,
  MN = 142,
  MD = 143,
  ME = 144,
  MS = 145,
  MA = 146,
  MZ = 147,
  OM = 148,
  OS = 149,
  NA = 150,
  NR = 151,
  NP = 152,
  NL = 153,
  CW = 154,
  AW = 155,
  SX = 156,
  BQ = 157,
  NC = 158,
  VU = 159,
  NZ = 160,
  NI = 161,
  NE = 162,
  NG = 163,
  NU = 164,
  NF = 165,
  NO = 166,
  MP = 167,
  UM = 168,
  FM = 169,
  MH = 170,
  PW = 171,
  PK = 172,
  PA = 173,
  PG = 174,
  PY = 175,
  PE = 176,
  PH = 177,
  PN = 178,
  PL = 179,
  PT = 180,
  GW = 181,
  TL = 182,
  PR = 183,
  QA = 184,
  RE = 185,
  RO = 186,
  RU = 187,
  RW = 188,
  BL = 189,
  SH = 190,
  KN = 191,
  AI = 192,
  LC = 193,
  MF = 194,
  PM = 195,
  VC = 196,
  SM = 197,
  ST = 198,
  SA = 199,
  SN = 200,
  RS = 201,
  SC = 202,
  SL = 203,
  SG = 204,
  SK = 205,
  VN = 206,
  SI = 207,
  SO = 208,
  ZA = 209,
  ZW = 210,
  ES = 211,
  SS = 212,
  SD = 213,
  EH = 214,
  SR = 215,
  SJ = 216,
  SZ = 217,
  SE = 218,
  CH = 219,
  SY = 220,
  TJ = 221,
  TH = 222,
  TG = 223,
  TK = 224,
  TO = 225,
  TT = 226,
  AE = 227,
  TN = 228,
  TR = 229,
  TM = 230,
  TC = 231,
  TV = 232,
  UG = 233,
  UA = 234,
  MK = 235,
  EG = 236,
  GB = 237,
  GG = 238,
  JE = 239,
  IM = 240,
  TZ = 241,
  US = 242,
  VI = 243,
  BF = 244,
  UY = 245,
  UZ = 246,
  VE = 247,
  WF = 248,
  WS = 249,
  YE = 250,
  ZM = 251,
}

export enum RegionEnum {
  _1 = "1",
  _2 = "2",
  _3 = "3",
  _4 = "4",
  _5 = "5",
  _6 = "6",
  _7 = "7",
  _8 = "8",
  _9 = "9",
  _10 = "10",
  _11 = "11",
  _12 = "12",
  _13 = "13",
  _14 = "14",
  _15 = "15",
  _16 = "16",
  _17 = "17",
  _18 = "18",
  _19 = "19",
  _20 = "20",
  _21 = "21",
  _22 = "22",
  _23 = "23",
  _24 = "24",
  _25 = "25",
  _26 = "26",
  _27 = "27",
  _28 = "28",
  _29 = "29",
  _30 = "30",
  _31 = "31",
  _32 = "32",
  _33 = "33",
  _34 = "34",
  _35 = "35",
  _36 = "36",
  _37 = "37",
  _38 = "38",
  _39 = "39",
  _40 = "40",
  _41 = "41",
  _42 = "42",
  _43 = "43",
  _44 = "44",
  _45 = "45",
  _46 = "46",
  _47 = "47",
  _48 = "48",
  _49 = "49",
  _50 = "50",
  _51 = "51",
  _52 = "52",
  _53 = "53",
  _54 = "54",
  _55 = "55",
  _56 = "56",
  _57 = "57",
  _58 = "58",
  _59 = "59",
  _60 = "60",
  _61 = "61",
  _62 = "62",
  _63 = "63",
  _64 = "64",
  _65 = "65",
  _66 = "66",
  _67 = "67",
  _68 = "68",
  _69 = "69",
  _70 = "70",
  _71 = "71",
  _72 = "72",
  _73 = "73",
  _74 = "74",
  _75 = "75",
  _76 = "76",
  _77 = "77",
  _78 = "78",
  _79 = "79",
  _83 = "83",
  _86 = "86",
  _87 = "87",
  _89 = "89",
  _90 = "90",
  _91 = "91",
  _92 = "92",
  _93 = "93",
  _94 = "94",
  _95 = "95",
  _99 = "99",
}

export enum AddressTypeEnum {
  // Юридический
  LEGAL = 1,
  // Фактический
  ACTUAL = 2,
}
